import React, {Fragment, useContext} from 'react';
import {SearchOutlined} from '@ant-design/icons';
import {navigate} from 'gatsby';
import MenuItem from '../../Components/MenuItem';
import {Input, Text} from '../../Widgets';
import {Context} from '../../AppContext';
import HashTag from '../../Components/ProductHashTag';

const config = require('../../data.json');

export default function ProductListSideSection({
  filters,
  onFilter,
  onCloseDrawer = () => {},
}) {
  const app = useContext(Context);
  const promozones = app.state.promoItems.filter(
    (x) => x.type === 'bottom_zone',
  );

  return (
    <Fragment>
      <Input
        value={filters.search}
        onChange={(e) => {
          let value = e.target.value;
          onFilter({search: value});
        }}
        margin="0"
        prefix={<SearchOutlined />}
      />

      <Text
        size="lg"
        color={config.colors.main}
        style={{margin: '20px 0 5px 0'}}>
        全部商品
      </Text>
      {app.state.categories &&
        app.state.categories.map((c, idx) => (
          <MenuItem
            key={idx}
            node={c}
            onClick={(c) => {
              navigate(`/products${c.name ? '?name=' + c.name : ''}`);
              onCloseDrawer();
            }}
            fontSize={config.fontSize.sm}
          />
        ))}

      <Text
        size="lg"
        color={config.colors.main}
        style={{margin: '20px 0 5px 0'}}>
        精選專區
      </Text>

      {promozones.length === 0
        ? null
        : // <LoadingBlock height="200"/>
          promozones.map((z, idx) => (
            <MenuItem
              key={idx}
              node={{display: z.label, name: z.name}}
              onClick={() => {
                navigate(`/products?zone=${z.id}`);
                onCloseDrawer();
              }}
              fontSize={config.fontSize.sm}
            />
          ))}

      <Text
        size="lg"
        color={config.colors.main}
        style={{margin: '20px 0 5px 0'}}>
        Hashtags
      </Text>

      <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: 30}}>
        {app.state.hashtags.length === 0
          ? null
          : // <LoadingBlock />
            app.state.hashtags.map((t, idx) => (
              <HashTag
                key={idx}
                selected={t === filters.hashtag}
                onClick={() => {
                  navigate(
                    `/products${t !== filters.hashtag ? '?hashtag=' + t : ''}`,
                  );
                  onCloseDrawer();
                }}>
                {`${t}`}
              </HashTag>
            ))}
      </div>
    </Fragment>
  );
}
