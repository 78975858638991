import React from 'react';
import {Context} from '../AppContext';
import {fnCmp, getCategoryFromLocation} from '../Utils';
const queryString = require('query-string');

function useProductList({location}) {
  const app = React.useContext(Context);
  const [filters, setFilters] = React.useState({
    category: null,
    sort: '-updated',
    search: '',
    hashtag: '',
    promozone: null,
  });
  const [products, setProducts] = React.useState([]);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    (async function () {
      app.actions.setLoading(true);
      setLoading(true);

      try {
        let resp = await app.actions.getProducts();
        setProducts(resp);
      } catch (ex) {}
      app.actions.setLoading(false);
      setLoading(false);
    })();
  }, [app.actions]);

  // handle query parameter -> change filters

  React.useEffect(() => {
    const {
      zone: zoneId,
      name: catName,
      hashtag = '',
    } = queryString.parse(location.search);
    const catTree = {name: 'root', children: app.state.categories};
    let category = getCategoryFromLocation(catName, catTree) || null;
    let promozone =
      app.state.promoItems.find((z) => z.id === parseInt(zoneId)) || null;

    if (!catName) {
      category = catTree;
    }

    setFilters((filters) => ({...filters, category, promozone, hashtag}));
  }, [location.search, app.state.categories, app.state.promoItems]);

  // filtering products
  const applyFilter = (products) => {
    const {category, sort, search, hashtag, promozone} = filters;
    const actions = {
      inCat: (p) => {
        if (
          category &&
          category.name !== '' &&
          category.name !== 'root' &&
          category.name !== '全部商品'
        ) {
          return p.label && p.label.indexOf(category.name) > -1;
        }
        return true;
      },
      inSpec: (p) => app.state.productNames.indexOf(p.name) > -1,
      inSearch: (p) => !search || p.name.indexOf(search) > -1,
      inHash: (p) => !hashtag || (p.hashtag && p.hashtag.indexOf(hashtag) > -1),
      inPromo: (p) =>
        !promozone ||
        (p.promo_zone && p.promo_zone.indexOf(promozone.name) > -1),
    };

    // filtering
    let result = products.filter(
      (p) =>
        actions.inCat(p) &&
        actions.inSpec(p) &&
        actions.inSearch(p) &&
        actions.inHash(p) &&
        actions.inPromo(p),
    );

    // sorting
    if (sort === '+price') {
      result = result.sort(fnCmp({key: 'price'}));
    } else if (sort === '-price') {
      result = result.sort(fnCmp({key: 'price', desc: true}));
    } else if (sort === '+updated') {
      result = result.sort(fnCmp({key: 'updated'}));
    } else if (sort === '-updated') {
      result = result.sort(fnCmp({key: 'updated', desc: true}));
    }

    return result;
  };

  return {
    loading,
    products,
    filters,
    setFilters,
    applyFilter,
    showDrawer,
    setShowDrawer,
  };
}

export default useProductList;
