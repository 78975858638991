import React, {useContext, useEffect, useState, Fragment} from 'react';
import {Context} from '../AppContext';

function catWalk(node, parent) {
  let result = [];
  node.parent = parent;
  result = result.concat([node]);

  for (let c of node.children) {
    result = result.concat(catWalk(c, node));
  }

  return result;
}

export default function CategoryBreadcrumb({node, goToCategory, style}) {
  const app = useContext(Context);
  const [catBacktrace, setBacktrace] = useState([]);
  const [categoryTreeWithParent, setCategoryTreeWithParent] = useState([]);

  useEffect(() => {
    if (app.state.categories) {
      let result = catWalk(
        {name: 'root', display: '全部商品', children: app.state.categories},
        null,
      );
      setCategoryTreeWithParent(result);
    }
  }, [app.state.categories]);

  useEffect(() => {
    let _node = categoryTreeWithParent.find((cat) => cat.name === node?.name);

    if (_node) {
      let catBacktrace = [_node];
      while (_node.parent) {
        catBacktrace.push(_node.parent);

        _node = _node.parent;
      }
      catBacktrace.reverse();
      setBacktrace(catBacktrace);
    }
  }, [node, categoryTreeWithParent]);

  return (
    <div style={{display: 'flex', ...style}}>
      {catBacktrace.map((cat, idx) => {
        const last = idx === catBacktrace.length - 1;
        return (
          <Fragment key={idx}>
            <div
              style={{
                fontSize: 10,
                marginRight: 10,
                flexGrow: 0,
                color: last ? 'black' : 'grey',
                cursor: 'pointer',
              }}
              onClick={() => goToCategory(cat)}>
              {`${cat.display}`}
            </div>
            {!last && (
              <div
                style={{
                  fontSize: 10,
                  marginRight: 10,
                  flexGrow: 0,
                  color: '#ccc',
                }}>
                {`/`}
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}
