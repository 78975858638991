import React from 'react';
import {Row, Col} from 'antd';
const appConfig = require('../data.json');

export function GridLayout({children}) {
  return (
    <Row
      gutter={[appConfig.productGridGutter, appConfig.productGridGutter]}
      style={{alignSelf: 'stretch'}}>
      {children}
    </Row>
  );
}

export function GridItem({children}) {
  return (
    <Col xs={12} md={8} lg={6}>
      {children}
    </Col>
  );
}
