import styled from 'styled-components';
const config = require('../../data.json');

const HashTag = styled.div`
  padding: 3px 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  margin-right: 5px;
  margin-bottom: 5px;
  color: ${config.colors.text};
  cursor: pointer;
  ${(props) =>
    props.selected &&
    `
    background-color: ${config.colors.second};
    color: white;
  `}

  &:hover {
    background-color: ${config.colors.second};
    color: white;
  }

  &::before {
    content: '#';
  }
`;

export default HashTag;
