import React, {Fragment} from 'react';
import CategoryBreadcrumb from '../../Components/CategoryBreadcrumb';
import {Select} from 'antd';
import {Text} from '../../Widgets';
import {ImageWrapper} from '../../Components/Image';
import Image from '../../Components/Image';
const config = require('../../data.json');

const RATIO = 440 / 940;

export default function TopSection({filters, onFilter}) {
  return (
    <Fragment>
      <div style={{display: 'flex', alignItems: 'center'}}>
        {filters.hashtag && (
          <p
            style={{
              fontSize: 20,
              color: config.colors.main,
              margin: 0,
              padding: 0,
            }}>{`#${filters.hashtag}`}</p>
        )}

        <div style={{flex: 1}} />

        <Select
          value={filters.sort}
          style={{
            width: 160,
            fontSize: 12,
            borderColor: config.colors.main,
          }}
          onChange={(value) => onFilter({sort: value})}>
          <Select.Option value="+updated" style={{fontSize: 12}} key="+updated">
            依日期排列(由舊到新)
          </Select.Option>
          <Select.Option value="-updated" style={{fontSize: 12}} key="-updated">
            依日期排列(由新到舊)
          </Select.Option>
          <Select.Option value="+price" style={{fontSize: 12}} key="+price">
            依價位排列(由低到高)
          </Select.Option>
          <Select.Option value="-price" style={{fontSize: 12}} key="-price">
            依價位排列(由高到低)
          </Select.Option>
        </Select>
      </div>

      {(filters.promozone && (
        <>
          <Image
            ratio={RATIO}
            style={{margin: '10px 0'}}
            src={`${filters.promozone.image}`}
            alt={
              '精選專區 ' +
              (filters.promozone.label ||
                filters.promozone.name ||
                '無此精選專區')
            }
          />
          <Text size="xl" color={config.colors.main} style={{padding: 5}}>
            {filters.promozone.label ||
              filters.promozone.name ||
              '無此精選專區'}
          </Text>
        </>
      )) || (
        <CategoryBreadcrumb
          node={filters.category}
          goToCategory={(value) => onFilter({category: value})}
          style={{marginBottom: 20, marginTop: 10}}
        />
      )}
    </Fragment>
  );
}
